.aboutUsContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 15rem;
}

.aboutUsContainer::before {
  content: " ";
  display: block;
  background-image: url("./../../assets/background.png"),
    linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.9));
  background-blend-mode: overlay;
  height: 100vh;
  width: 100vw;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: -10;
}

.aboutUs {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  background: rgb(255, 255, 255, 0.75);
  padding: 6rem 4rem;
  margin: 2rem 0rem;
}

.aboutUs p:not(:first-child) {
  padding-top: 2rem;
}

.aboutUs p b {
  padding-top: 2rem;
  display: flex;
  font-family: "Cedarville Cursive", cursive;
  font-size: 3rem;
  font-weight: 300;
  line-height: 3.75rem;
  justify-content: center;
  text-align: center;
}

.dabbawalaImg {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  /* display: none; */
}

.dabbawalaImg > img {
  height: 20rem;
}

@media (orientation: portrait) {
  .aboutUsContainer {
    padding: 1rem 1rem;
  }

  .aboutUs {
    padding: 3rem 2rem;
    font-size: 1.35rem;
    line-height: 1.75;
  }

  .valueProp {
    flex-direction: column;
  }

  .dabbawalaImg > img {
    max-width: 100%;
    height: auto;
  }

  .aboutUs p b {
    font-size: 2.5rem;
    line-height: 1.25;
    text-align: center;
  }
}

@media only screen and (max-width: 400px) {
  .aboutUs {
    font-size: 1rem;
    line-height: 1.75;
  }

  .aboutUs p b {
    font-size: 2rem;
    line-height: 1.5;
  }
}
