.wrapperPrivacyPolicy {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 2rem 15rem;
}

.wrapperPrivacyPolicy::before {
  content: " ";
  display: block;
  background-image: url("./../../assets/background.png"),
    linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.9));
  background-blend-mode: overlay;
  height: 100vh;
  width: 100vw;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: -10;
}

.mainContentPrivacyPolicy {
  flex: 1;
  background: rgba(255, 255, 255, 0.75);
  border-radius: 1.5rem;
  padding: 6rem 4rem;
  margin: 2rem 0rem;
  font-size: 1rem;
  text-align: justify;
}

.wrapperPrivacyPolicy ul,
.wrapperPrivacyPolicy ol,
.wrapperTOS ul,
.wrapperTOS ol {
  margin-top: 1rem;
  margin-left: 3.25rem;
  text-align: left;
  text-align: justify;
  line-height: 1.6rem;
}

.wrapperPrivacyPolicy li {
  margin-bottom: 0.75rem;
}

.item {
  margin-bottom: 3rem;
}

.item > p {
  line-height: 1.65rem;
  margin-top: 0.75rem;
}

@media (orientation: portrait) {
  .wrapperPrivacyPolicy {
    padding: 0rem 1rem 0rem 1rem;
    display: flex;
  }

  .mainContentPrivacyPolicy {
    padding: 2rem;
    margin: 2rem 0rem;
  }
}
