.valuePropContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 15rem;
}

.valuePropContainer::before {
  content: " ";
  display: block;
  background-image: url("./../../assets/background.png"),
    linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.9));
  background-blend-mode: overlay;
  height: 100vh;
  width: 100vw;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: -10;
}

.valueProp {
  display: flex;
  align-items: center;
  background: rgb(255, 255, 255, 0.75);
  padding: 6rem 4rem;
  margin: 2rem 0rem;
}

.tiffinImg img {
  margin-left: 3rem;
  margin-right: -5rem;
}

.valueProp li {
  list-style-position: inside;
  padding-bottom: 3rem;
  font-size: 1.5rem;
}

.valueProp li:last-child {
  padding-bottom: 0rem;
}

@media (orientation: portrait) {
  .valuePropContainer {
    padding: 1rem 1rem;
  }

  .valueProp {
    flex-direction: column;
    padding: 3rem 2rem;
  }

  .valueProp li {
    font-size: 1.35rem;
    line-height: 1.75;
  }

  .valueProp li b {
    display: inline-block;
    padding-bottom: 0.65rem;
  }

  .tiffinImg img {
    display: none;
    height: 35vh;
    margin: 0 0 3rem 0;
  }
}

@media only screen and (max-width: 400px) {
  .valueProp li {
    font-size: 1rem;
  }
}
