.wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  /* background-image: url("./../../assets/background.png"),
    linear-gradient(rgba(255, 255, 255, 0.82), rgba(255, 255, 255, 0.85));
  background-blend-mode: overlay; */
  padding: 2rem 15rem;
}

.wrapper::before {
  content: " ";
  display: block;
  background-image: url("./../../assets/background.png"),
    linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.9));
  background-blend-mode: overlay;
  height: 100vh;
  width: 100vw;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: -10;
}

.heroSection {
  display: flex;
  justify-content: space-evenly;
  column-gap: 5rem;
  flex: 1;
  background: rgba(255, 255, 255, 0.75);
  border-radius: 1.5rem;
  padding: 6rem;
  margin: 2rem 0rem;
}

.namaste {
  display: none;
}

.heroImage {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
  flex-shrink: 1;
  margin-right: -1.5rem;
}

.heroImage img {
  height: auto;
  width: 27vw;
  object-fit: contain;
}

/* .rotate {
  animation: rotation 180s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 2;
  flex-shrink: 2;
  max-width: 60%;
  font-weight: 400;
  font-size: 2.6rem;
}

.message {
  display: flex;
  width: fit-content;
  align-items: center;
  margin-top: 1.25rem;
  border-radius: 1rem;
}

.success {
  background-color: rgba(42, 226, 42, 0.4);
  color: rgb(70, 70, 70);
}

.danger {
  background-color: rgba(255, 106, 0, 0.95);
  color: white;
}

.message span {
  align-items: center;
  font-size: 1.15rem;
  padding: 0.75rem 1rem;
  font-weight: 300;
  margin-right: 5rem;
}

.closeIcon {
  margin-right: 0.5rem;
  font-size: 2rem;
  cursor: pointer;
}

.emailForm > form {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 90%;
  max-width: 70rem;
  column-gap: 0.75rem;
}

.emailForm .inputs {
  display: flex;
  flex-shrink: 1;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
  flex-shrink: 1;
}

input:focus::placeholder {
  color: transparent;
}

.emailForm > form > .inputs > input:first-child {
  width: 100%;
  min-width: min(30vw, 100%);
  max-width: 35rem;
  font-size: 1.75rem;
  padding: 0.65rem 1.15rem;
  border-radius: 2rem;
}

.emailForm .legal {
  display: flex;
  align-items: center;
  flex-shrink: 1;
  margin: 1rem 0;
  font-size: 1.1rem;
  font-weight: 300;
  padding-left: 0.45rem;
  color: rgb(141, 141, 141);
}

.legal label {
  margin-left: 0.75rem;
  line-height: 1.65rem;
  flex-shrink: 1;
}

.emailForm button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  min-height: 3.25rem;
  min-width: 10rem;
  font-size: 1.85rem;
  font-weight: 700;
  padding: 0.6rem 1rem;
  border-radius: 1rem;
  background-color: rgb(255, 27, 115);
  color: white;
  border: 2px solid;
  cursor: pointer;
}

.btn-loading {
  display: none;
}

.boldText {
  display: inline;
}

@media only screen and (max-width: 1580px) {
  .wrapper {
    padding: 3rem 2rem 2rem 2rem;
  }
}

@media only screen and (max-width: 1000px) {
  .emailForm > form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1;
  }

  .emailForm button {
    margin-left: 0;
  }
}

@media (orientation: portrait) {
  .namaste {
    display: flex;
    height: 4rem;
    margin: 2rem 0 1rem 0;
  }

  .wrapper {
    padding: 1rem 1rem 0rem 1rem;
    display: flex;
  }

  .heroSection {
    flex-direction: column;
    column-gap: 0;
    flex: 1;
    justify-content: space-evenly;
    padding: 2rem 1rem;
    margin: 2rem 0rem;
  }

  .heroImage {
    padding-top: 2rem;
    justify-content: center;
    margin: 0;
  }

  .heroImage img {
    width: 100%;
    max-height: 40vh;
    margin-top: 1rem;
    padding: 1rem;
  }

  .text {
    font-size: 1.7rem;
    max-width: 100%;
    align-items: center;
  }

  .text p {
    text-align: center;
    padding-top: 2rem;
  }

  .emailForm {
    display: flex;
    width: 95%;
    justify-content: center;
  }

  .message span {
    padding: 0.75rem 0.5rem;
    text-align: center;
    font-size: 1rem;
    margin: 0;
  }

  .emailForm .legal {
    padding-left: 0.25rem;
  }

  .emailForm > form {
    display: flex;
    flex-grow: 1;
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
  }

  .emailForm .inputs {
    align-items: center;
    width: 90%;
  }

  .emailForm > form > .inputs > input:first-child {
    font-size: 1.25rem;
    text-align: center;
    flex-grow: 1;
  }

  .legal label {
    font-size: 0.6rem;
    line-height: normal;
    /* text-align: center; */
    margin-left: 0.5rem;
  }

  .emailForm button {
    font-size: 1.25rem;
    margin-top: 0.25rem;
  }
}

@media (orientation: portrait) and (min-width: 800px) {
  .wrapper {
    padding: 1rem;
    justify-content: center;
  }

  .heroSection {
    padding: 2rem 4rem;
    margin: 2rem 2rem;
  }
}

@media only screen and (max-width: 400px) {
  .text {
    font-size: 1.2rem;
  }

  .namaste {
    height: 3rem;
  }

  .emailForm > form {
    margin-top: 1.25rem;
  }

  .emailForm .legal {
    margin: 0.5rem 1.25rem 0.5rem 0;
    text-align: center;
  }

  .emailForm > form > .inputs > input:first-child {
    font-size: 1.1rem;
    line-height: 1.25rem;
  }

  .legal label {
    font-size: 0.5rem;
  }

  .emailForm button {
    font-size: 1.1rem;
    margin-top: 0;
    padding: 0.5rem 1rem;
  }
}
