.imprintWrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 2rem 15rem;
}

.imprintWrapper::before {
  content: " ";
  display: block;
  background-image: url("./../../assets/background.png"),
    linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.9));
  background-blend-mode: overlay;
  height: 100vh;
  width: 100vw;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: -10;
}

.imprintSection {
  background: rgb(255, 255, 255, 0.75);
  padding: 6rem 4rem;
  margin: 2rem 0rem;
  font-size: 1rem;
  line-height: 2.25rem;
}

.imprintSection p {
  line-height: 1.75rem;
  padding-bottom: 0.75rem;
}

.imprintSection h1,
.imprintSection h2,
.imprintSection h3 {
  padding-bottom: 0.75rem;
}

@media (orientation: portrait) {
  .imprintWrapper {
    padding: 1rem 1rem;
  }

  .imprintSection {
    padding: 3rem 2rem;
    font-size: 0.75rem;
  }

  .imprintSection p {
    line-height: 1.5rem;
    padding-bottom: 0.5rem;
  }

  .imprintSection h1,
  .imprintSection h2,
  .imprintSection h3 {
    padding-bottom: 0.25rem;
  }
}
