nav {
  padding: 1rem 15rem 0rem 15rem;
  display: flex;
  position: sticky;
  top: 0;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(255, 248, 122);
  z-index: 999;
}

.logo {
  display: flex;
  padding-top: 0.5rem;
}

.logo > a > img {
  height: 3.5rem;
  animation: shake 0.75s;
  animation-iteration-count: 3.25;
}

.logo a:first-child:hover {
  animation: shake 0.75s;
  animation-iteration-count: 3.5;
}

@keyframes shake {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  25% {
    transform: translate(2.5px, 0.5px) rotate(5deg);
  }
  50% {
    transform: translate(0, 0) rotate(0eg);
  }
  75% {
    transform: translate(-2.5px, 0.5px) rotate(-5deg);
  }
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}

.logoText {
  font-family: "Cedarville Cursive", cursive;
  color: rgb(70, 59, 133);
  font-weight: 900;
  font-size: 2.5rem;
  padding-left: 0.75rem;
}

.logo a:nth-child(2) {
  text-decoration: none;
}

nav ul {
  display: flex;
  justify-content: flex-end;
}

nav ul li {
  list-style: none;
}

nav ul li a {
  text-decoration: none;
  color: rgb(70, 59, 133);
  font-weight: 500;
  font-size: 1.5rem;
}

nav ul li a:hover {
  text-decoration: none;
  color: rgb(130, 130, 130);
}

nav ul li a:active,
nav ul nav a:first-child {
  text-decoration: none;
}

.menu {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.menu.open {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 6.25rem;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: #f8f8f8; */
  background-color: #fdfdf0;
  /* background-color: #463b85; */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  visibility: visible; /* Add this line */
  opacity: 0.975; /* Add this line */
  transition: visibility 0s, opacity 0.5s; /* Add this line */
}

.menu.open li {
  margin-bottom: 2rem;
}

.menuItems {
  display: flex;
  column-gap: 4rem;
}

.mobileMenuIcon {
  display: none;
  cursor: pointer;
  font-size: 1.5rem;
  padding: 0rem 0.5rem 0rem 0.5rem;
}

.mobileMenuIcon > svg {
  color: rgb(94, 87, 136);
}

@media only screen and (max-width: 1580px) {
  nav {
    padding: 1rem 2rem 0rem 2rem;
  }
}

@media only screen and (max-width: 1000px) {
  .menu {
    display: none;
  }

  .mobileMenuIcon {
    display: block;
  }

  .menuItems {
    flex-direction: column;
    align-items: center;
    margin-top: -5rem;
  }

  nav ul li a {
    font-weight: 900;
    font-size: 1.85rem;
  }
}

@media only screen and (max-width: 500px) {
  .logo {
    padding: 0;
  }

  .logoText {
    font-size: 2rem;
  }

  .logo > a > img {
    height: 3rem;
  }

  .menu.open {
    top: 4.75rem;
  }
}

@media only screen and (max-width: 400px) {
  .logoText {
    font-size: 1.5rem;
  }

  .logo > a > img {
    height: 2.25rem;
  }

  nav ul li a {
    font-weight: 500;
    font-size: 1.25rem;
  }

  .menu.open {
    top: 3.75rem;
  }
}

@media (orientation: portrait) {
  nav {
    padding: 1rem 1rem 0rem 1rem;
  }
}
