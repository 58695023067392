.footer {
  background-color: #fff87a;
  padding: 0.5rem 1rem 0.25rem;
  font-size: 0.85rem;
  text-align: center;
  color: #484848;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 0.25rem;
}

.footer svg {
  font-size: 1.35rem;
  color: rgb(67, 67, 67);
}

.socialMediaIcons {
  display: flex;
  column-gap: 0.5rem;
  /* margin-bottom: -0.5rem; */
}

@media (orientation: landscape) {
  .footer {
    flex-direction: row;
    column-gap: 1rem;
    align-items: flex-start;
  }
}
